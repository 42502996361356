import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DomainService } from '../services/domain.service';
import { Observable } from 'rxjs';
import { Domain } from '../models/Domain';
@Injectable()
export class DomainResolver {
  router: any;
  constructor(private domainService: DomainService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Domain> {
    return this.domainService.getAll(route.paramMap.get('id'));
  }
}
