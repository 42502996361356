<section class="section">
  <div class="section-header">
    <h2>Add Domain</h2>
  </div>

  <div class="user-form">
    <form [formGroup]="form" (keyup.enter)="save()" (ngSubmit)="save()" tabindex="0">
      <mat-form-field [floatLabel]="options.value.floatLabel">
        <mat-label>Domain Name</mat-label>
        <input matInput formControlName="name" data-lpignore = "true" id = "name-search">
      </mat-form-field>

      <div mat-dialog-actions>
        <button type="button" mat-raised-button (click)="cancel()">Cancel</button>
        <button type="button" *ngIf="data.id" mat-raised-button color="warn" (click)="deleteDomain()">Delete</button>
        <button type="submit" mat-raised-button color="primary">Save</button>
      </div>
    </form>
  </div>
</section>
