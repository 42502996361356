import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Domain } from '../../core/models/Domain';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { DomainService } from 'src/app/core/services/domain.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-domain-dialog',
  templateUrl: './domain.dialog.html',
  styleUrls: ['./domain.dialog.scss']
})
export class DomainDialogComponent implements OnInit {
  options: UntypedFormGroup;
  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<DomainDialogComponent>,
    private domainService: DomainService,
    @Inject(MAT_DIALOG_DATA) public data: Domain,
    fb: UntypedFormBuilder
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: 'auto'
    });
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
    });
  }

  public save() {
    this.dialogRef.close({
      ...this.form.value,
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public deleteDomain() {
    return this.domainService
      .deleteDomain(this.data.program_id, this.data.id)
      .pipe(first())
      .subscribe(() => {
        this.dialogRef.close({
          delete: true
        });
      });
  }
}
